import * as React from 'react'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { ProductListing } from '../components/ProductListing'
import CollectionLinks from '../components/shopify/collection-tags'
import { s, sHtml } from '../style'

export default function ProductCollection({
  data: {
    collection,
    translatedCollection,
    products,
    translatedProducts,
    products2,
    seo
  },
  pageContext: { handle, title },
}) {
  const shopifyProductList = products.nodes
  const shopifyTranslatedProductList = translatedProducts.nodes
  const matchedTranslatedProducts = []

  shopifyTranslatedProductList.forEach((translatedProduct) => {
    const matchedProduct = shopifyProductList.find(
      product => product.storefrontId === translatedProduct.storefrontId
    )

    if (matchedProduct) {
      const images = matchedProduct.images
      const priceRangeV2 = matchedProduct.priceRangeV2
      const variants = matchedProduct.variants
      const metafields = matchedProduct.metafields

      const mergedProduct = {
        ...translatedProduct,
        images,
        priceRangeV2,
        variants,
        metafields,
      }

      matchedTranslatedProducts.push(mergedProduct)
    }
  })

  const contentfulProductList = products2.nodes.filter(
    (item) => item['orderNo'] !== null
  )

  const productList = matchedTranslatedProducts
    .map((shopifyProduct) => ({
      ...shopifyProduct,
      ...contentfulProductList.find(
        (contentfulProduct) => contentfulProduct.slug === shopifyProduct.handle
      ),
    }))
    .sort((a, b) => a.orderNo - b.orderNo)

  const seoImagePath = getSrc(collection.image)
  const desc = translatedCollection?.descriptionHtml

  return (
    <Layout white>
      <Seo
        title={translatedCollection.seo.title ? translatedCollection.seo.title : ''}
        desc={translatedCollection.seo.description ? translatedCollection.seo.description : ''}
        imageUrl={seoImagePath}
        url={`shop/` + handle}
        isCollection
      />
      <section css={sOuter}>
        <CollectionLinks />
        <h1 css={sHeadline}>{title}</h1>
        {desc !== undefined && desc !== "" && (
          <div css={[sHtml, sParagraph]} dangerouslySetInnerHTML={{ __html: desc }} />
        )}
        {productList.length > 0 ? (
          <ProductListing products={productList} />
        ) : (
          <div css={sParagraph} dangerouslySetInnerHTML={{ __html: `No products in collection.` }} />
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query (
    $id: String!
    $translatedId: String!
    $locale: String!
    $storefrontId: String!
  ) {
    collection: shopifyCollection(id: { eq: $id }) {
      seo {
        description
        title
      }
      description
      image {
        gatsbyImageData
      }
    }
    translatedCollection: shopifyTranslatedCollection(
      id: { eq: $translatedId }
    ) {
      description
      descriptionHtml
      seo {
        description
        title
      }
    }
    products: allShopifyProduct(
      filter: { collections: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        ...ProductCard
        storefrontId
      }
    }
    translatedProducts: allShopifyTranslatedProduct(
      filter: {
        collections: {
          edges: { elemMatch: { node: { id: { eq: $storefrontId } } } }
        }
      }
    ) {
      nodes {
        id
        title
        handle
        storefrontId
      }
    }
    products2: allContentfulProdukt(
      sort: { order: ASC, fields: orderNo }
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        slug
        orderNo
      }
    }
  }
`

const sOuter = {
  height: 'max-content',
  minHeight: '100vh',
  width: '100%',
  maxWidth: '1500px',
  position: 'relative',
  top: 0,
  left: 0,
  margin: '0 auto',
  padding: '12rem 2rem 10rem',
  [s.xs]: {
    padding: '8rem 2rem',
  },
}

const sParagraph = {
  'li': {
    fontSize: '1rem',
    marginBottom: '0.5rem',
    color: '#2b2a29'
  },
  'ul': {
    color: '#2b2a29'
  },
  'p + ul': {
    marginBottom: '1rem'
  },
  // lineHeight: '2em',
  // fontSize: '1rem',
  // opacity: '0.6',
  // marginBottom: '3rem',
  [s.md]: {
    fontSize: '1.15rem',
    padding: '0 2rem 0',
  },
}

const sHeadline = {
  margin: '0 auto 2rem',
  fontSize: '3rem',
  [s.md]: {
    padding: '0 2rem',
  },
}

const sShopWrapper = {
  width: '100%',
  // maxWidth: 1500,
  margin: 'auto',
  gap: '3rem',
  marginTop: '2rem',

  [s.md_down]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [s.xs]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  'span' : {
    color: 'white'
  }
}
